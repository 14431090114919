import React, { useState, useEffect } from 'react';
import HeadBar from '../components/HeadBar';
import { sendVerificationCode, fetchProtectedData } from '../js/dbUserInfo';
import { checkLogin } from '../js/checkLogin';
import './DashboardPage.css';


function Dashboard() {
  const [isLoading, setIsLoading] = useState(true); // Ladezustand

  const handleSendCode = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      await sendVerificationCode(code);
      await fetchProtectedData();
  
      const newURL = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, newURL);
    } else {
      if (!localStorage.getItem('userInfo')) {
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
          window.location.href = '/login';
        }
      }
    }
  };

  useEffect(() => {
    const sendCodeAndLoad = async () => {
      await handleSendCode();
      setIsLoading(false);
    };

    sendCodeAndLoad();
  }, []);

  if (isLoading) {
    return (
      <div className="loader-screen">
        <div className="spinner"></div>
      </div>
    );
  }

  const data = JSON.parse(localStorage.getItem('bundledData'));
  console.log('Benutzerdaten abgerufen!', data);

  const handleBotClick = (uuid) => {
    window.location.href = `https://ayodev.de/dashboard/bot?uuid=${uuid}`
  };

  return (
    <div className="homepage">
      <HeadBar />
      <div className="bot-container">
        {data.botsData.botInfo.map((bot, index) => (
          <div key={index} className="bot-card" onClick={() => handleBotClick(bot.UUID)}>
            <img src={bot.avatar_url} alt={`${bot.bot_name} Avatar`} className="bot-avatar" />
            <h3>{bot.bot_name}</h3>
            <p>Bot ID: {bot.UUID}</p>
            <p>Guild ID: {bot.guild_id}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;