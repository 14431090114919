export async function sendUUID(uuid) {
    const url = `https://ayodev.de:8443/api/botToken`;
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ uuid }),
        });

        if (!response.ok) {
            throw new Error(`HTTPS error! status: ${response.status}`);
        }
        
        const data = await response.json();

        localStorage.setItem('botToken', data.botToken);
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function fetchProtectedData() {
    const url = `https://ayodev.de:8443/api/botInfo`;
    const token = localStorage.getItem('token');
    const botToken = localStorage.getItem('botToken');

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ botToken }),
        });

        if (!response.ok) {
            throw new Error(`HTTPS error! status: ${response.status}`);
        }

        const data = await response.json();

        localStorage.setItem('botDetails', JSON.stringify(data.botDetails[0]));
    } catch (error) {
        console.error('Error:', error);
    }
}