export async function sendVerificationCode(code) {
    const url = `https://ayodev.de:8443/api/verify`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code }),
        });

        if (!response.ok) {
            throw new Error(`HTTPS error! status: ${response.status}`);
        }

        const data = await response.json();

        localStorage.setItem('token', data.token);
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function fetchProtectedData() {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('Token ist nicht verfügbar.');
    }

    try {
        const userDataResponse = await fetch('https://ayodev.de:8443/api/user-data', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.toString()}`
            },
        });

        if (!userDataResponse.ok) {
            throw new Error(`Fehler: ${userDataResponse.status} ${userDataResponse.statusText}`);
        }

        const userData = await userDataResponse.json();

        const botsDataResponse = await fetch('https://ayodev.de:8443/api/bots', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.toString()}`
            },
        });

        if (!botsDataResponse.ok) {
            throw new Error(`Fehler: ${botsDataResponse.status} ${botsDataResponse.statusText}`);
        }

        const botsData = await botsDataResponse.json();

        const bundledData = { userData, botsData };

        localStorage.setItem('bundledData', JSON.stringify(bundledData));
    } catch (error) {
        console.error('Fehler bei der Anfrage:', error);
    }
}