import React from 'react';
import HeadBar from '../components/HeadBar';
import { checkLogin } from '../js/checkLogin';

function HomePage() {
  if (checkLogin()) {
    window.location.href = '/dashboard';
  }
  return (
    <div className="homepage">
      <HeadBar />
      <h1 className="discription-text">Hosten deinen Eigenen Bot<br></br>100% Einstellbar</h1>
    </div>
  );
}

export default HomePage;