import React, { useState, useEffect } from 'react';
import HeadBar from '../components/HeadBar';
import BotHero from '../components/BotHero';
import { sendUUID, fetchProtectedData } from '../js/botInfo.js';
import { checkLogin } from '../js/checkLogin';
import './BotPage.css';

function BotPage() {
    const [isLoading, setIsLoading] = useState(true);

    const handleSendCode = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get('uuid');
        if (uuid) {
          localStorage.setItem('uuid', uuid);
          await sendUUID(uuid);
          await fetchProtectedData();

          const newURL = window.location.href.split('?')[0];
          window.history.replaceState({}, document.title, newURL);
        } else {
          if (!localStorage.getItem('userInfo')) {
            const isLoggedIn = await checkLogin();
            if (!isLoggedIn) {
              window.location.href = '/login';
            }
          }
        }
      };

      useEffect(() => {
        const sendCodeAndLoad = async () => {
          await handleSendCode();
          setIsLoading(false);
        };
    
        sendCodeAndLoad();
      }, []);
    
      if (isLoading) {
        return (
          <div className="loader-screen">
            <div className="spinner"></div>
          </div>
        );
      }

      return (
        <div>
          <HeadBar />
          <BotHero />
        </div>
      );
}

export default BotPage;