import React from 'react';
import './LoginPage.css'; 
import { checkLogin } from '../js/checkLogin';

function LoginPage() {
  if (checkLogin()) {
    window.location.href = '/dashboard';
  }
  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">AyoBots</h1>
        <p className="login-description">Mit Discord anmelden</p>
        <a href="https://discord.com/oauth2/authorize?client_id=1188249005252165725&response_type=code&redirect_uri=https%3A%2F%2Fayodev.de%2Fdashboard&scope=identify+guilds+email" className="login-button">
          <span className="material-icons">login</span> Mit Discord anmelden
        </a>
        <p className="login-privacy">
          Damit das Dashboard funktionieren kann, werden sogenannte Cookies zur Anmeldung und Zahlungsabwicklung verwendet. Es werden keine Cookies zum Tracken verwendet.
        </p>
        <p className="login-privacy">
          Wenn du fortfährst, akzeptierst du unsere <a href="/privacy">Datenschutzrichtlinien</a> und die AyoBots Nutzungsbedingungen.
        </p>
      </div>
    </div>
  );
}

export default LoginPage;