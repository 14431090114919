export default function BotHero() {
    const data = JSON.parse(localStorage.getItem('bundledData'));
    const BotList = data.botsData.botInfo;
    const ServerList = JSON.parse(data.userData.userData.guilds);

    console.log(ServerList);

    var botData;
    var guildData;
    var perm8;

    BotList.forEach(bot => {
        if (bot.UUID === localStorage.getItem('uuid')) {
            botData = bot;
        }
    });

    ServerList.forEach(server => {
        if (server.id === botData.guild_id) {
            guildData = server;
        }
    });
    
    console.log(guildData);

    function ServerInfos() {
        if (guildData) {
            return (
            <div className="serverInfos">
                <h1>{guildData.name}</h1>
                <div className="imageWrapper">
                    <img src={guildData.icon || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSieyaZb-hSOtWnc6wha3QQlMLL8_cfvr2WIQ&s'} alt="Icon" />
                </div>
                <div className="imageWrapper">
                    <img src={guildData.banner || 'h</div>ttps://www.adweek.com/wp-content/uploads/2021/07/DiscordLogo.jpg?w=890'} alt="Banner" />
                </div>
                <button className="switchGuildButtons" onClick={handleSwitchGuild}>
                    Switch Guild
                </button>
            </div>
            )
        } else {
            return (
                <div className="serverInfos">
                    <h1>Not in a server</h1>
                    <button className="switchGuildButtons" onClick={handleSwitchGuild}>
                        Switch Guild
                    </button>
                </div>
            )
        }
    }

    return (
        <div className="botHero">
            <div className="botInfos">
                <h1>{botData.bot_name}</h1>
                <div className="imageWrapper">
                    <img src={botData.avatar_url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSieyaZb-hSOtWnc6wha3QQlMLL8_cfvr2WIQ&s'} alt="Avatar" />
                    <button className="editButton" onClick={handleEditAvatar}>
                        <i className="fas fa-pencil-alt"></i>
                    </button>
                </div>
                <div className="imageWrapper">
                    <img src={botData.banner_url || 'https://www.adweek.com/wp-content/uploads/2021/07/DiscordLogo.jpg?w=890'} alt="Banner" />
                    <button className="editButton" onClick={handleEditBanner}>
                        <i className="fas fa-pencil-alt"></i>
                    </button>
                </div>
            </div>
            <ServerInfos />
        </div>
    )

    function handleEditAvatar() {
        // Logic to edit avatar
    }

    function handleEditBanner() {
        // Logic to edit banner
    }

    function handleSwitchGuild() {
        // Logic to switch guild
    }
}