import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.gif';

function HeadBar() {
  return (
    <div className="topbar">
      <div className="topbar-left">
        <img src={logo} className="topbar-logo" alt="logo" />
        <p id="topbar-name">AyoBots | </p>
        <div className="topbar-info">
          <p>Info 1</p>
          <p>Info 2</p>
        </div>
      </div>
      <div className="topbar-right">
        <Link to="/login" className="login-button">Login</Link>
      </div>
    </div>
  );
}

export default HeadBar;
